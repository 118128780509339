import formatCreatedAt from '@helpers/methods/formatCreatedAt';
import sortByPublishedDate from '@helpers/methods/sortByPublishedDate';

import selectAuthor from '../utils/selectAuthor';
import sanitizeArray from '../utils/sanitizeArray';
import selectContentImage from '../utils/selectContentImage';

const generateNewsCardsProps = (news) => {
  const newsCardsProps = news.map((news) => {
    let newsCardProps = news;
    newsCardProps = selectAuthor(newsCardProps, news);
    newsCardProps = selectContentImage(newsCardProps, news);
    return newsCardProps;
  });

  return newsCardsProps;
};

const getNewsCardsProps = (news) => {
  let newsCardsProps = sanitizeArray(news);
  newsCardsProps = formatCreatedAt(newsCardsProps);
  newsCardsProps = sortByPublishedDate(newsCardsProps);
  newsCardsProps = generateNewsCardsProps(newsCardsProps);

  return newsCardsProps;
};

export default getNewsCardsProps;
